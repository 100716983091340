import React, { useState } from "react";
import { Component } from "react";
//import './styles/navbar.css';
import { BrowserRouter, Route, Link } from "react-router-dom";
//import {FaBars, FaTimes} from "react-icons/fa";
import {useRef} from 'react';
import {Navbar, Nav, Container} from 'react-bootstrap';
import axios from "axios";
import './styles/navbar.css';


function NavbarTop() {
    return(
            <Navbar fixed="top" expand="lg" bg="white" variant="black" className="navbar-all">
            <Container>
                <Navbar.Brand className='nav-main' href="/">Insert Image</Navbar.Brand>
                <Navbar.Toggle aria-controls="navbarScroll" data-bs-target="#navbarScroll"/>
                <Navbar.Collapse id="navbarScroll">
                        <Nav className="ms-auto">
                            <Nav.Link className='nav-link lato-thick' href="/">Homepage</Nav.Link>
                            <Nav.Link className='nav-link lato-thick' href="Services">Services</Nav.Link>
                            <Nav.Link className='nav-link lato-thick' href="Profile"><button className="nav-btn">Book Free Call Now</button></Nav.Link>
                        </Nav>
                </Navbar.Collapse>
            </Container>
            </Navbar>
    )
}

export default NavbarTop;