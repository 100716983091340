import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './styles/services.css';

const Services = () => {
    return(
        <>
        <div className='services'>
            <Container>
                <p align="center" className='lato-black title pink'>How can we help?</p>
                <p align="center" className='lato-regular normal-text black'>There are numerous services that we provide that will scale your income efficiently. A lot of OnlyFans creators leave a lot of money on the table being a solo team, that's why we have dedicated teams to help with each aspect of your business.</p>
                <Row>
                <div className='black-line'>
                </div>
                    <Col xs={12} sm={12} lg={4} xl={4}>
                        <div className='text-center-container'>
                            <p className='lato-bold sub-title pink'>Marketing</p>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} lg={4} xl={4}>
                        <p className='lato-regular black'>We will manage all your Social Media Platforms and schedule all your content. We will use our expertise to ensure that customer aquisition is good and you won't have to press a button.</p>
                    </Col>
                    <Col xs={12} sm={12} lg={4} xl={4}>
                        <div className='text-center-container'>
                            <button className='action-btn'>Book a Call.</button>
                        </div>
                    </Col>
                </Row>
                <Row>
                <div className='black-line'>
                </div>
                    <Col xs={12} sm={12} lg={4} xl={4}>
                        <div className='text-center-container'>
                            <p className='lato-bold sub-title pink'>OnlyFans Management</p>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} lg={4} xl={4}>
                        <p className='lato-regular black'>We will take control of your OnlyFans, don't worry, you will still have access. By doing this we can assign our content and messaging team to manage your profile so you can reap the rewards.</p>
                    </Col>
                    <Col xs={12} sm={12} lg={4} xl={4}>
                        <div className='text-center-container'>
                            <button className='action-btn'>Book a Call.</button>
                        </div>
                    </Col>
                </Row>
                <Row>
                <div className='black-line'>
                </div>
                    <Col xs={12} sm={12} lg={4} xl={4}>
                        <div className='text-center-container'>
                            <p className='lato-bold sub-title pink'>OnlyFans Messaging</p>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} lg={4} xl={4}>
                        <p className='lato-regular black'>We have a dedicated team who will handle all your messages on OnlyFans 24/7, this way they can use their expertise to ensure you are getting the most out of your customers and extract value.</p>
                    </Col>
                    <Col xs={12} sm={12} lg={4} xl={4}>
                        <div className='text-center-container'>
                            <button className='action-btn'>Book a Call.</button>
                        </div>
                    </Col>
                </Row>
                <Row>
                <div className='black-line'>
                </div>
                    <Col xs={12} sm={12} lg={4} xl={4}>
                        <div className='text-center-container'>
                            <p className='lato-bold sub-title pink'>DMCA Partners</p>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} lg={4} xl={4}>
                        <p className='lato-regular black'>Tired of your OnlyFans content being leaked? Not only is this demorilising, but it damages your earnings more than you think. We have dedicated DMCA partners who scan the internet for your content and remove it.</p>
                    </Col>
                    <Col xs={12} sm={12} lg={4} xl={4}>
                        <div className='text-center-container'>
                            <button className='action-btn'>Book a Call.</button>
                        </div>
                    </Col>
                    <div className='black-line'>
                    </div>
                </Row>
                
            </Container>
            
        </div>
        
        </>
    )
}

export default Services;