import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './styles/homepage.css';
import Services from './services';

const Homepage = () => {
    return(
        <>
        <div className='homepage'>
            <Container>
                <Row>
                    <Col xs={12} sm={12} lg={12} xl={12}>
                        <div className='text'>
                            <p align="left" className='lato-black title black'>We help you scale your income using <span class="pink">our Expertise.</span></p>
                            <p align="left" className='lato-bold medium black'>Join us at Pulse Mangement and watch your income grow whilst freeing up your time.</p>
                            <a href="https://calendly.com/contact-17qw/30min?preview_source=et_card&month=2024-05"><button className='action-btn'>Book a Free Call Today</button></a><button className='action-btn-white'>How can we help?</button>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
        <Services/>
        </>
    )
}

export default Homepage;